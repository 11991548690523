<template>
  <v-container
    id="pricelist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Цены на обучение
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Цены на обучение</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="createPrice"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  :label="onlyArchivedLabel"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['order']"
            :sort-desc="[false]"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                  @click="singlePrice(item)"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td><span v-if="item.course">{{ item.course.name }}</span></td>
                  <td><span v-html="item.priceSummary" /></td>
                  <td>{{ item.comment }}</td>
                  <td>
                    <v-btn
                      color="primary"
                      small
                      @click.stop="orderUp(item.id)"
                    >
                      <v-icon>
                        mdi-arrow-up
                      </v-icon>
                    </v-btn>
                    <v-btn
                      color="primary"
                      small
                      @click.stop="orderDown(item.id)"
                    >
                      <v-icon>
                        mdi-arrow-down
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .v-data-table tr {
    cursor: pointer;
  }

  .help-pointer {
    cursor: pointer;
  }
</style>

<script>
  import priceApi from '../services/PriceApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    data () {
      return {
        search: CRMStorage.getItem('PL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('PL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('PL_onlyArchived', false),
        headers: [
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Название',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Курс',
            value: 'course.name',
          },
          {
            sortable: true,
            text: 'Цены',
            value: 'priceSummary',
          },
          {
            sortable: true,
            text: 'Дополнительная информация',
            value: 'comment',
          },
          {
            sortable: false,
            text: 'Порядок',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
        ],
        items: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },

    computed: {
      onlyArchivedLabel () {
        if (this.items.length < 1) {
          return 'Показать архивные'
        }
        return 'Показать архивные (' + this.items.filter((item) => item.status === 0).length + ')'
      },
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('PL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('PL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('PL_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('PL_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('PL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('PL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Цены на обучение | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      this.fetchData()
    },
    methods: {
      singlePrice (item) {
        CRMStorage.setItem('PL_scrollY', window.scrollY)
        this.$router.push('/price/' + item.id)
      },
      createPrice () {
        CRMStorage.setItem('PL_scrollY', window.scrollY)
        this.$router.push('/price/create')
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
      orderUp (id) {
        this.loading = true
        priceApi
          .orderUp(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      orderDown (id) {
        this.loading = true
        priceApi
          .orderDown(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      fetchData () {
        this.loading = true
        priceApi
          .fetchPrices()
          .then(response => {
            this.items = response
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },
    },
  }
</script>
<style>
  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }
  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }
  .help-pointer {
    cursor: pointer;
  }
</style>
